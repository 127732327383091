export function usePreviousRoute() {
  const route = useRoute();

  try {
    const previous = useRouter().options.history.state.back;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const resolved = useRouter().resolve(previous as string) as any;

    if (resolved.path !== route.path) return resolved;
  } catch {
    return null;
  }
}
